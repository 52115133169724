/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import RefundInstruction from "../../../../../shared/test-cases/pbc-refund-instruction.mdx";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h2: "h2",
    br: "br",
    h3: "h3",
    ol: "ol",
    li: "li",
    h4: "h4",
    pre: "pre",
    code: "code",
    ul: "ul"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "A merchant can use their POS app to initiate an unreferenced refund on the same payment device."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " To run a referenced refund, view our ", React.createElement(_components.a, {
    href: "../guides/integrate/refunds/referenced-refund-card"
  }, "refund guide"), ".")), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, "To run an unreferenced refund using Payroc Cloud, integrate with the following:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Launch the Payroc app service.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Create a payment instruction.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 3."), " Switch to the Payroc app.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 4."), " View the status of the payment instruction.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 5."), " View the details of the payment.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 6."), " Switch to your POS app."), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate a Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. Start the Payroc app service"), "\n", React.createElement(_components.p, null, "Before you run an unreferenced refund, we recommend that you run a command to start the Payroc app service on the payment device."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test package name:"), " com.payroc.test", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production package name:"), " com.payroc"), "\n", React.createElement(_components.h3, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-kotlin"
  }, "    val payrocServiceIntent = Intent()\n    payrocServiceIntent.component = ComponentName(PACKGAGE_NAME, \"wn.pax.mitc.service.PBCService\")\n    if (Build.VERSION.SDK_INT >= Build.VERSION_CODES.O) {\n        applicationContext.startForegroundService(payrocServiceIntent)\n    } else {\n        applicationContext.startService(payrocServiceIntent)\n    }\n")), "\n", React.createElement(_components.h2, null, "Step 2. Create a refund instruction"), "\n", React.createElement(_components.p, null, "To send a refund instruction to a device, send a POST request to the Devices endpoint.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/devices/%7BserialNumber%7D/refund-instructions"
  }, "https://api.uat.payroc.com/v1/devices/{serialNumber}/refund-instructions"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/devices/%7BserialNumber%7D/refund-instructions"
  }, "https://api.payroc.com/v1/devices/{serialNumber}/refund-instructions")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "sendRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "sendRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we send the refund instruction to the device."), React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " The response returns a value of ", React.createElement("code", null, "inProgress"), " for ", React.createElement(_components.strong, null, "status"), " and an identifier for the instruction that you can use to check the status of the instruction. To get a link to the refund, go to Step 2.")), React.createElement(_components.p, null, "The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "sendRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "sendRefundInstruction"
  })), "\n", React.createElement(_components.h2, null, "Step 3. Switch to the Payroc app"), "\n", React.createElement(_components.p, null, "After you receive a response for the refund instruction with a status of ", React.createElement(_components.code, null, "inProgress"), ", switch to the Payroc app on the payment device."), "\n", React.createElement(_components.h3, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-kotlin"
  }, "    val payrocAppIntent = Intent()\n    payrocAppIntent.component = ComponentName(PACKGAGE_NAME, \"wn.pax.mitc.MITCActivity\")\n    payrocAppIntent.flags = FLAG_ACTIVITY_NEW_TASK\n    applicationContext.startActivity(payrocAppIntent)\n")), "\n", React.createElement(_components.h2, null, "Step 4. View the status of a refund instruction"), "\n", React.createElement(_components.p, null, "To check for updates to the status or view the details of the refund, send a GET request to the Refund Instructions endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/refund-instructions/%7BrefundInstructionId%7D"
  }, "https://api.uat.payroc.com/v1/refund-instructions/{refundInstructionId}"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/refund-instructions/%7BrefundInstructionId%7D"
  }, "https://api.payroc.com/v1/refund-instructions/{refundInstructionId}")), "\n", React.createElement(_components.p, null, "Before our gateway sends a response, it waits for up to 30 seconds for the status of the instruction to change. We recommend that you keep the session open until the status of the instruction changes or the request times out."), "\n", React.createElement(_components.p, null, "If the status of the instruction doesn’t change, send another GET request. Our gateway waits up to 30 seconds for the status of the instruction to change. Continue to send GET requests until the status changes."), "\n", React.createElement(Admonition, {
    type: "important"
  }, React.createElement(_components.p, null, "Wait until you receive a response from our gateway before you send another request.")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "getRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "getRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we return the details of the refund instruction."), React.createElement(_components.p, null, "If the status of the refund instruction is ", React.createElement("code", null, "inProgress"), ", our gateway waits up to a minute for the status to change before it returns a response."), React.createElement(ResponseBody, {
    operationId: "getRefundInstruction"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "getRefundInstruction"
  })), "\n", React.createElement(_components.h2, null, "Step 5. View the details of the refund"), "\n", React.createElement(_components.p, null, "To check whether the processor approved or declined the refund, send a GET request to the Refunds endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/refunds/%7BrefundId%7D"
  }, "https://api.uat.payroc.com/v1/refunds/{refundId}"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/refunds/%7BrefundId%7D"
  }, "https://api.payroc.com/v1/refunds/{refundId}")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "getRefund"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "getRefund"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we return the details of the refund."), React.createElement(ResponseBody, {
    operationId: "getRefund"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "getRefund"
  })), "\n", React.createElement(_components.h2, null, "Step 6. Switch to your POS app"), "\n", React.createElement(_components.p, null, "After you receive the details of the refund, switch back to your POS app, and then display a receipt."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-kotlin"
  }, "    val launchIntent = packageManager.getLaunchIntentForPackage(applicationContext.packageName)\n    applicationContext.startActivity(launchIntent)\n")), "\n", React.createElement(_components.h2, null, "Before you test your integration"), "\n", React.createElement(_components.p, null, "To test your integration with a payment device, first complete the following tasks:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Get the serial number of the payment device."), "\n", React.createElement(_components.li, null, "Connect the payment device to the internet."), "\n"), "\n", React.createElement(_components.h2, null, "Test cases"), "\n", React.createElement(_components.h3, null, "Create a refund instruction for when the card isn’t present"), "\n", React.createElement(RefundInstruction));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
